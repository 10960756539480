import UserInterface from '@/interfaces/dummy/user';
import { Commit } from 'vuex';

interface UserState {
  token: string;
  user: UserInterface;
}

const defaultState = (): UserState => {
  return {
    token: '',
    user: {
      username: '',
      email: '',
      profileImageLocation: '',
      token: '',
      _id: null,
      description: '',
      firstName: '',
      lastName: '',
    },
  };
};

const state = defaultState();

const getters = {
  getUser(state: UserState): UserInterface {
    return state.user;
  },
  getUserId(state: UserState): string {
    return state.user._id!;
  },
  getToken(state: UserState): string {
    return state.token;
  },
  isLoggedIn(state: UserState): boolean {
    if (state.user && state.token) {
      return state.user._id !== null && state.token.length > 0;
    }
    return false;
  },
};

const actions = {
  login({ commit }: { commit: Commit }, user: UserState): void {
    commit('SET_USER', user.user);
    commit('SET_TOKEN', user.token);
  },
  logout({ commit }: { commit: Commit }): void {
    commit('LOGOUT');
  },
  updateUser({ commit }: { commit: Commit }, user: UserInterface): void {
    commit('SET_USER', user);
  },
};

const mutations = {
  SET_USER(state: UserState, payload: UserInterface): void {
    console.log(state.user);
    state.user = payload;
  },
  SET_TOKEN(state: UserState, payload: string): void {
    state.token = payload;
  },
  LOGOUT(state: UserState): void {
    const loggedOutUser = defaultState();
    state.user = loggedOutUser.user;
    state.token = loggedOutUser.token;
  },
};

export default {
  getters,
  state,
  namespaced: true,
  actions,
  mutations,
};
