import { Commit } from 'vuex';

interface HeaderState {
  header: string;
  title: string;
  subtext: string;
  useFancyTest: boolean;
}

const getters = {
  getHeader(state: HeaderState): string {
    return state.header;
  },
  getTitle(state: HeaderState): string {
    return state.title;
  },
  getSubtext(state: HeaderState): string {
    return state.subtext;
  },
  getFancyText(state: HeaderState): boolean {
    return state.useFancyTest;
  },
};

const actions = {
  // setHeader({ commit }: { commit: Commit }, payload: string): void {
  //   commit('setHeader', payload);
  // },
  setTitle({ commit }: { commit: Commit }, payload: string): void {
    commit('setTitle', payload);
  },
};

const mutations = {
  // setHeader(state: HeaderState, header: string): void {
  //   state.header = header;
  // },
  setTitle(state: HeaderState, title: string): void {
    state.title = title;
  },
};

export default {
  getters,
  // userState,
  namespaced: true,
  actions,
  mutations,
};
