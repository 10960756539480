
import { defineComponent } from 'vue';
import TopNavigation from '@/components/navigation/TopNavigation.vue';

export default defineComponent({
  components: {
    TopNavigation,
  },
  setup() {
    console.log(
      'Browser thumbnail icon by: https://www.freepik.com/free-vector/flat-design-flower-pixel-art-illustration_23320819.htm#query=dragonfly%20pixel%20art&position=12&from_view=search&track=ais'
    );
    return {};
  },
});
