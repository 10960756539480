import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import { ElNotification } from 'element-plus';
import store from '@/store/index';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/blog',
    alias: '/',
    name: 'home',
    component: () => import('../views/Public/BlogHome.vue'),
  },
  {
    path: '/blog/:slug',
    name: 'blog',
    props: true,
    component: () => import('../views/Public/BlogView.vue'),
  },
  {
    path: '/register',
    name: 'register',
    props: true,
    component: () => import('../views/Public/RegisterView.vue'),
  },
  {
    path: '/category/:slug',
    name: 'category',
    props: true,
    component: () => import('../views/Public/CategorySearch.vue'),
  },
  {
    path: '/login',
    name: 'login',
    props: true,
    component: () => import('../views/Public/LoginView.vue'),
  },
  {
    path: '/logout',
    name: 'logout',
    props: true,
    component: () => import('../views/Admin/AdminLogout.vue'),
  },
  {
    path: '/admin/:userId',
    component: () => import('../views/Admin/AdminHome.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/admin/:userId/new',
    component: () => import('../views/Admin/AdminNewBlog.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/admin/:userId/profile',
    component: () => import('../views/Admin/AdminProfile.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/admin/:userId/drafts',
    component: () => import('../views/Admin/AdminDrafts.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/admin/:userId/posts',
    component: () => import('../views/Admin/AdminPosts.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/admin/:userId/edit/:slug',
    component: () => import('../views/Admin/AdminEdit.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/admin/:userId/draft/:slug',
    name: 'View Draft Admin',
    props: true,
    meta: {
      requiresAuth: true,
    },
    component: () => import('../views/Admin/AdminViewPost.vue'),
  },
  {
    path: '/admin/:userId/blog/:slug',
    name: 'View Post Admin',
    props: true,
    meta: {
      requiresAuth: true,
    },
    component: () => import('../views/Admin/AdminViewPost.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    // always scroll 10px above the element #main
    return {
      // could also be
      // el: document.getElementById('main'),
      el: '#app',
      top: 0,
    };
  },
});

function tokenIsExpired(token: string): boolean {
  const data = JSON.parse(atob(token.split('.')[1]));
  const tokenExp = new Date(data.exp * 1000);
  return tokenExp < new Date();
}

router.beforeEach((to, from, next): void => {
  const token = store.getters['userStore/getToken'];
  const user = store.getters['userStore/getUser'];
  const isLoggedIn = store.getters['userStore/isLoggedIn'];

  if (to.name === 'logout' && !isLoggedIn) {
    router.push('/');
  }

  // if route requires authorization
  if (to.meta.requiresAuth) {
    if (!isLoggedIn) {
      router.push('/');
      return;
    }
    if (!user || to.params.userId !== user._id) {
      store.dispatch('userStore/logout');
      router.push('/');
    }
    if (tokenIsExpired(token)) {
      store.dispatch('userStore/logout');
      ElNotification({
        title: 'Session',
        message: 'session expired - please login again',
        type: 'info',
      });
      router.push('/login');
      return;
    }
  }
  next();
});

export default router;
