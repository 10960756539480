import { createApp } from 'vue';
import ElementPlus from 'element-plus';
import App from './App.vue';
import 'element-plus/theme-chalk/index.css';
import '../public/assets/styles/flexy.css';
import '../public/assets/styles/reset.css';
import '../public/assets/styles/app.css';
import '../public/assets/styles/blog-show.css';
import router from './router';
import store from './store';

createApp(App).use(store).use(ElementPlus).use(router).mount('#app');
