
import { defineComponent, computed } from 'vue';
import store from '@/store/index';

export default defineComponent({
  setup() {
    const isLoggedIn = computed((): boolean => {
      return store.getters['userStore/isLoggedIn'];
    });
    const userId = computed((): number => {
      return store.getters['userStore/getUser']._id;
    });
    const customAdminRoute = computed((): string => {
      return `/admin/${userId.value}`;
    });
    return { isLoggedIn, customAdminRoute };
  },
});
