/* eslint-disable import/no-extraneous-dependencies */
import { createStore } from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import userStore from './modules/user';
import headerStore from './modules/header';

export default createStore({
  modules: {
    userStore,
    headerStore,
  },
  plugins: [createPersistedState()],
});
